$(function () {
    // masks
    var maskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11
                ? '(00) 00000-0000'
                : '(00) 0000-00009'
        },
        options = {
            onKeyPress: function (val, e, field, options) {
                field.mask(maskBehavior.apply({}, arguments), options)
            },
        }
    $('.form-tel').mask(maskBehavior, options)
    $('.form-cep').mask('00000-000')

    // Fancybox
    Fancybox.bind()

    // Swipper
    var swiper = new Swiper('.swiperNumeros', {
        pagination: {
            el: '.swiper-pagination-numeros',
        },
        slidesPerView: 1,
        breakpoints: {
            992: {
                slidesPerView: 4,
            },
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    })

    var swiperFormas = new Swiper('.swiperFormas', {
        pagination: {
            el: '.swiper-pagination-formas',
        },
        slidesPerView: 1,
        breakpoints: {
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
                slidesPerGroup: 3,
            },
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    })

    // Inc track
    $('.btn-whatsapp').on('click', function () {
        inc_event(
            'click_botao',
            'click_type',
            'compartilhar_whatsapp',
            'click_position',
            [nomedadobra]
        )
        console.log('Clique no botão WhatsApp rastreado')
    })

    console.log(
        '%c  💾  IncDigital.com.br  |  GA4 Inicializado ',
        'background: #8034ff; color: #fff; font-size:16px; padding:4px;border-radius:10px'
    )

    function inc_event(
        event,
        parametro1_tipo,
        parametro1_valor,
        parametro2_tipo,
        parametro2_valor
    ) {
        var obj = {
            'inc-event': event,
            'inc-parametro1_tipo': parametro1_tipo,
            'inc-parametro1_valor': parametro1_valor,
            'inc-parametro2_tipo': parametro2_tipo,
            'inc-parametro2_valor': parametro2_valor,
        }

        dataLayer.push(obj)
        dataLayer.push({
            event: 'inc-event',
        })
        console.log(
            '%c 🚀 IncDigital.com.br  |  GA4 Event: ',
            'background: #8034ff; color: #fff; font-size:16px; padding:4px;border-radius:10px'
        )
        console.log(
            '%c' + JSON.stringify(obj),
            'background: #33FFAF; color: #000; font-size:14px; padding:4px;border-radius:10px'
        )
        console.log(obj)
    }
})
